import * as React from 'react';

import {
  ContactHome,
  GiftVoucher,
  Hero,
  Layout,
  Map,
  SEO,
  ServicesSection,
  Why,
} from '../components';
import { CTAs } from '../components/ctas';

export default function IndexPage() {
  return (
    <>
      <SEO title="Day Spa | Massage | Beauty" />
      <Layout>
        <Hero />
        <CTAs />
        <Why />
        <ServicesSection />
        <GiftVoucher />
        <ContactHome />
        <Map />
      </Layout>
    </>
  );
}
