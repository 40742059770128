import { graphql, Link, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';

const SERVICES_QUERY = graphql`
  {
    allCtasJson {
      nodes {
        id
        cta
        description
        href
        image {
          childImageSharp {
            gatsbyImageData(width: 576)
          }
        }
        title
      }
    }
  }
`;

function CTAs() {
  const { allCtasJson: services } = useStaticQuery(SERVICES_QUERY);
  return (
    <section
      aria-labelledby="pricing-heading"
      className="relative z-20 mt-4 lg:-mt-16"
    >
      <h2 id="pricing-heading" className="sr-only">
        Services
      </h2>

      {/* Services */}
      <div className="max-w-2xl px-4 mx-auto space-y-12 sm:px-6 lg:max-w-7xl lg:space-y-0 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8">
        <div className="flex justify-center col-span-3 mt-8 lg:hidden">
          <a
            href="https://phorest.com/book/salons/portmacquariedayspa"
            rel="noopener noreferrer"
            target="_blank"
            className="inline-flex items-center px-4 space-x-2 text-xl font-light leading-6 text-gray-500 uppercase transition duration-150 ease-in-out border border-gray-400 group hover:text-white hover:bg-gray-800 hover:border-gray-800 focus:outline-none focus:text-white focus:border-gray-800 focus:bg-gray-800 active:bg-gray-900"
          >
            <span>Book now</span>
          </a>
        </div>
        {services.nodes.map((service) => (
          <div
            key={service.id}
            className="flex flex-col overflow-hidden rounded-lg shadow-lg"
          >
            <div className="flex-shrink-0 aspect-w-4 aspect-h-3">
              <div className="flex bg-gray-50">
                <GatsbyImage
                  image={service.image.childImageSharp.gatsbyImageData}
                  className="flex-1"
                  alt=""
                />
              </div>
              <div
                aria-hidden
                className="absolute inset-0 bg-white bg-opacity-25 pointer-events-none"
              />
            </div>
            <div className="flex flex-col justify-between flex-1 p-6 bg-white">
              <div className="flex-1">
                <a href={service.href} className="block mt-2">
                  <p className="font-serif text-xl font-semibold text-gray-900 uppercase">
                    {service.title}
                  </p>
                  <p className="mt-3 text-base text-gray-500">
                    {service.description}
                  </p>
                </a>
              </div>
              <div>
                <p>
                  <Link
                    to={service.href}
                    className="inline-block px-6 py-2 mt-8 text-sm font-normal tracking-wider text-center uppercase transition duration-150 ease-in-out border border-gray-900 text-rose-700 hover:bg-gray-900 hover:text-white"
                  >
                    {service.cta}
                  </Link>
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export { CTAs };
